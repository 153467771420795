import React, { useState } from 'react';
import { DateTime } from 'luxon';
import StaffContenu from './StaffContenu';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import EditSpeaker from './EditSpeaker';
import { PhotoForUtilisateur } from '../../components/UI/Photo';
import Pagination from '../../components/UI/Pagination';

const Utilisateurs = () => {
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [editingUtilisateur, setEditingUtilisateur] = useState(null);
  const [page, setPage] = useState(0);
  const [data] = useRestClient(`staff/utilisateurs/?roles=SPEAKER&page=${page}`, null, [lastAction]);

  if (!data) {
    return <Chargement />;
  }
  const utilisateurs = data.results;

  return (
    <StaffContenu titre={!editingUtilisateur ? 'Speakers/Moderators' : `Edit : ${editingUtilisateur.firstname} ${editingUtilisateur.lastname}`}>
      {(editingUtilisateur) ? (
        <EditSpeaker
          utilisateurId={editingUtilisateur.id}
          onCancel={() => {
            setEditingUtilisateur(null);
          }}
          onSuccess={() => {
            setEditingUtilisateur(null);
            setLastAction(DateTime.local());
          }}
        />
      ) : (
        <>

          <table className="stdTable table-100">
            <thead>
              <tr>
                <th>Avatar</th>
                <th>First name</th>
                <th>Last name</th>
                <th>Company</th>
                <th>Position</th>
                <th>Hide list</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {utilisateurs.map((utilisateur) => (
                <tr key={utilisateur.id}>
                  <td><button type="button" onClick={() => setEditingUtilisateur(utilisateur)}><PhotoForUtilisateur utilisateur={utilisateur} small /></button></td>
                  <td><button type="button" onClick={() => setEditingUtilisateur(utilisateur)}>{utilisateur.firstname}</button></td>
                  <td><button type="button" onClick={() => setEditingUtilisateur(utilisateur)}>{utilisateur.lastname}</button></td>
                  <td>{utilisateur.company}</td>
                  <td>{utilisateur.position}</td>
                  <td>{utilisateur.bCacherSpeaker ? 'yes' : 'no'}</td>

                  <td className="stdSplitActions">
                    <button type="button" onClick={() => setEditingUtilisateur(utilisateur)}>edit</button>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
          <div className="center bigPadding-y">
            {data.pagesCount > 1 && <Pagination nbPages={data.pagesCount} changePage={setPage} actPage={page} total={data.total} /> }
          </div>

        </>
      )}
    </StaffContenu>
  );
};

export default Utilisateurs;
