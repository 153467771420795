import React, { useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import Panorama, {
  PANORAMA_PAGE_INSTITUTE_AI, PANORAMA_PAGE_INSTITUTE_HEALTHCARE,
  PANORAMA_PAGE_INSTITUTE_PRESENTATION, PANORAMA_PAGE_INSTITUTE_ROBOTICS, PANORAMA_PAGE_INSTITUTE_SUSTAINABILITY, PANORAMA_PAGE_LIBRARY_CONTENTBOOK,
  PANORAMA_PAGE_LIBRARY_CONTENTCOFFEETAB,
  PANORAMA_PAGE_LIBRARY_CONTENTQUOTE, PANORAMA_PAGE_LIBRARY_VIDEOGALLERY, PANORAMA_PAGE_LOBBY_FIIINSTUTELOUNGE, PANORAMA_PAGE_LOBBY_HELPDESK, PANORAMA_PAGE_LOBBY_WELCOME, PANORAMA_PAGE_INSTITUTE_HEALTHCARE_REPORT
} from '../Panorama';
import WhoIsWho from '../WhoIsWho/WhoIsWho';
import {
  PAGE_CONCLAVE, PAGE_INTERVIEW, PAGE_LIVE, PAGE_LOBBY, PAGE_NETWORKING,
} from '../Header/Header';
import InfoDesk from '../InfoDesk/InfoDesk';
import { LiveWithChat } from '../Live/Live';
import ButtonClose from '../../components/UI/ButtonClose';
import Button from '../../components/UI/Button';
import useInterface from '../../Lib/useInterface';
import Program from '../Program/Program';
import Interviews from '../Interview/Interviews';
import LibraryQuotes from '../Library/LibraryQuotes';
import LibraryVideos from '../Library/LibraryVideos';
import LibraryCoffeeTab, { FiiInstituteLoungeLogos } from '../Library/LibraryCoffeeTab';
import InstitutePresentation from '../Institute/InstitutePresentation';
import LibraryBooks from '../Library/LibraryBooks';
import useBackButton from '../../Lib/useBackButton';
import isMobile from '../../Lib/misc/isMobile';
import Video from '../../components/UI/Video';

const TopWindowVideoPopup = ({ setCurrentTopPanoramaPage, url, withBack }) => {
  useBackButton(() => setCurrentTopPanoramaPage());
  return (
    <div className="withBack">
      <ButtonClose
        backgrounded
        onClick={() => {
          setCurrentTopPanoramaPage(null);
        }}
      />
      <Video url={url} autoPlay />
    </div>
  );
};

const TopWindow = () => {
  const currentPage = useSelector((state) => state.interfaceDataReducer.currentPage);

  const isFinished = parseInt(useSelector((state) => state.stateDataReducer.config.isFinished)) === 1;
  const [currentTopPanoramaPage, setCurrentTopPanoramaPage] = useState(null);
  const [programOpened, setProgramOpened] = useState(false);
  const { changePage } = useInterface();

  const getAddedClasses = () => {
    if (currentPage === PAGE_LIVE) {
      return 'stdMainSpace-topWindow-overlayPage-forLive';
    }
    if ([
      PANORAMA_PAGE_LOBBY_WELCOME,
    ].includes(currentTopPanoramaPage)) {
      return 'stdMainSpace-topWindow-overlayPage-smallVideo';
    }
    if ([
      PANORAMA_PAGE_LIBRARY_CONTENTBOOK,
    ].includes(currentTopPanoramaPage)) {
      return 'stdMainSpace-topWindow-overlayPage-coverAll';
    }
    if ([
      PANORAMA_PAGE_INSTITUTE_AI,
      PANORAMA_PAGE_INSTITUTE_HEALTHCARE,
    ].includes(currentTopPanoramaPage)) {
      return 'stdMainSpace-topWindow-overlayPage-bigVideo stdMainSpace-topWindow-overlayPage-bigVideo-1';
    }
    if ([
      PANORAMA_PAGE_INSTITUTE_ROBOTICS,
      PANORAMA_PAGE_INSTITUTE_SUSTAINABILITY,
    ].includes(currentTopPanoramaPage)) {
      return 'stdMainSpace-topWindow-overlayPage-bigVideo stdMainSpace-topWindow-overlayPage-bigVideo-2';
    }
    if ([
      PANORAMA_PAGE_LOBBY_HELPDESK,
      PANORAMA_PAGE_LIBRARY_CONTENTQUOTE,
      PANORAMA_PAGE_LIBRARY_VIDEOGALLERY,
      PANORAMA_PAGE_INSTITUTE_PRESENTATION,
      PANORAMA_PAGE_INSTITUTE_HEALTHCARE_REPORT,
    ].includes(currentTopPanoramaPage)) {
      return 'stdMainSpace-topWindow-overlayPage-coverAll';
    }
    return null;
  };

  return (
    <>
      <div className={clsx('stdMainSpace-topWindow')}>
        { (currentTopPanoramaPage || currentPage === PAGE_LIVE) && (
          <div className={clsx('stdMainSpace-topWindow-overlayPage', getAddedClasses())}>
            {(() => {
              if (currentPage === PAGE_LIVE) {
                return (
                  <>
                    <div>
                      <ButtonClose backgrounded onClick={() => { changePage(PAGE_LOBBY); }} />
                      <LiveWithChat />

                      { !isFinished && (
                      <>
                        { (!programOpened && !isMobile()) ? (
                          <div className="center midMargin-t">
                            <Button variant="gradiented big rounded" onClick={() => setProgramOpened(!programOpened)}>Program</Button>
                          </div>
                        ) : <Program onClose={() => setProgramOpened(false)} withBackground />}
                      </>
                      )}
                    </div>
                  </>
                );
              }
              switch (currentTopPanoramaPage) {
                case PANORAMA_PAGE_LOBBY_WELCOME: {
                  return <TopWindowVideoPopup withBack url="https://player.vimeo.com/video/504878618" setCurrentTopPanoramaPage={setCurrentTopPanoramaPage} />;
                }
                case PANORAMA_PAGE_LOBBY_FIIINSTUTELOUNGE: {
                  return <FiiInstituteLoungeLogos onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                case PANORAMA_PAGE_INSTITUTE_AI: {
                  return <TopWindowVideoPopup url="https://www.youtube.com/watch?v=zjyZ1aTRqk0&t=4s" setCurrentTopPanoramaPage={setCurrentTopPanoramaPage} />;
                }
                case PANORAMA_PAGE_INSTITUTE_HEALTHCARE: {
                  return <TopWindowVideoPopup url="https://www.youtube.com/watch?v=rTc9--c38Sc" setCurrentTopPanoramaPage={setCurrentTopPanoramaPage} />;
                }
                case PANORAMA_PAGE_INSTITUTE_ROBOTICS: {
                  return <TopWindowVideoPopup url="https://www.youtube.com/watch?v=ZC6u0GTMyCM" setCurrentTopPanoramaPage={setCurrentTopPanoramaPage} />;
                }
                case PANORAMA_PAGE_INSTITUTE_SUSTAINABILITY: {
                  return <TopWindowVideoPopup url="https://www.youtube.com/watch?v=vAEKwBKjmXY" setCurrentTopPanoramaPage={setCurrentTopPanoramaPage} />;
                }
                case PANORAMA_PAGE_LOBBY_HELPDESK: {
                  return <InfoDesk withBackground onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                case PANORAMA_PAGE_LIBRARY_CONTENTQUOTE: {
                  return <LibraryQuotes onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                case PANORAMA_PAGE_LIBRARY_CONTENTBOOK: {
                  return <LibraryBooks onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                case PANORAMA_PAGE_LIBRARY_VIDEOGALLERY: {
                  return <LibraryVideos onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                case PANORAMA_PAGE_LIBRARY_CONTENTCOFFEETAB: {
                  return <LibraryCoffeeTab onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                case PANORAMA_PAGE_INSTITUTE_PRESENTATION: {
                  return <InstitutePresentation onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                case PANORAMA_PAGE_INSTITUTE_HEALTHCARE_REPORT: {
                  return <InstitutePresentation healthcareReport onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                default: {
                  return null;
                }
              }
            })()}

          </div>
        )}
        {(() => {
          switch (currentPage) {
            case PAGE_NETWORKING: {
              return <WhoIsWho />;
            }
            case PAGE_CONCLAVE: {
              return <Interviews isConclave />;
            }
            case PAGE_INTERVIEW: {
              return <Interviews />;
            }
            default:
            case PAGE_LIVE:
            case PAGE_LOBBY: {
              return <Panorama onSetPanoramaPage={setCurrentTopPanoramaPage} />;
            }
          }
        })()}
      </div>
    </>
  );
};

export default TopWindow;
