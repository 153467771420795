import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from '../components/UI/Button';
import useRestClient from '../Lib/useRestClient';
import Chargement from '../components/UI/Chargement';

const Visio = ({ programmeId, onBack }) => {
  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const iframeRef = useRef(null);

  const [programme] = useRestClient(`speaker/programmes/${programmeId}`);

  useEffect(() => () => {
    if (iframeRef.current) {
    //   iframeRef.current.contentWindow.postMessage('hello', '*');
    }
  }, []);

  if (!programme) {
    return <Chargement />;
  }

  window.onmessage = (e) => {
    // console.log(e.data);
    // if (e.data.meetingNumber) {
    //   setMeetingNumber(e.data.meetingNumber);
    // }
    if (e.data.action && e.data.action === 'leave') {
      onBack();
    }
  };

  return (

    <div className="stdSpeakerSpace-visio">
      <div className="stdSpeakerSpace-visio-top">
        <h1>{programme.name}</h1>

        <h2>Zoom meeting id: {programme.zoomReunionId} password: {programme.zoomPassword}</h2>
        <Button onClick={onBack} small>Back</Button>
      </div>

      <iframe src={`visio.html?pseudo=${loggedUtilisateur.firstname}&meetingPassword=${programme.zoomPassword}&meetingId=${programme.zoomReunionId}`} frameBorder="0" title="visio" ref={iframeRef} />

      {/* <button */}
      {/*  className="stdBtn" */}
      {/*  onClick={() => { */}
      {/*    iframeRef.current.contentWindow.postMessage('hello', '*'); */}
      {/*  }} */}
      {/* >Envoyer message */}
      {/* </button> */}
    </div>
  );
};

Visio.propTypes = {
  programmeId: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
};
export default Visio;
