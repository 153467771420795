import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import TopWindow from './TopWindow';
import BottomWindow from './BottomWindow';
import useSecurity from '../../Lib/useSecurity';
import SpeakerSpace from '../StaffSpace/SpeakerSpace';
import Footer from './Footer';
import { PAGE_LIVE, PAGE_LOBBY } from '../Header/Header';
import StickyFooter from './StickyFooter';

const MainSpace = () => {

  const currentPage = useSelector((state) => state.interfaceDataReducer.currentPage);

  return (
    <div className="stdMainSpace">
      <div className={clsx('stdContainer', ![PAGE_LOBBY, PAGE_LIVE].includes(currentPage) && 'stdContainer-withPadding')}>
        <TopWindow />
      </div>

      <StickyFooter />

    </div>
  );
};

export default MainSpace;
