import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Chargement from '../components/UI/Chargement';
import useInterface from '../Lib/useInterface';
import { PAGE_LIVE } from './Header/Header';

const PANORAMA_PAGE_LOBBY_WELCOME = 'lobby_welcome';
const PANORAMA_PAGE_LOBBY_HELPDESK = 'lobby_helpDesk';
const PANORAMA_PAGE_LOBBY_PLENARY = 'lobby_plenary';
const PANORAMA_PAGE_LOBBY_FIIINSTUTELOUNGE = 'lobby_fiiInstituteLounge';
const PANORAMA_PAGE_LIBRARY_VIDEOGALLERY = 'library_videoGallery';
const PANORAMA_PAGE_LIBRARY_CONTENTQUOTE = 'library_contentQuote';
const PANORAMA_PAGE_LIBRARY_CONTENTCOFFEETAB = 'library_contentCoffeeTab';
const PANORAMA_PAGE_LIBRARY_CONTENTBOOK = 'library_contentBook';
const PANORAMA_PAGE_INSTITUTE_PRESENTATION = 'institute_presentation';
const PANORAMA_PAGE_INSTITUTE_AI = 'institute_ai';
const PANORAMA_PAGE_INSTITUTE_ROBOTICS = 'institute_robotics';
const PANORAMA_PAGE_INSTITUTE_HEALTHCARE = 'institute_healthcare';
const PANORAMA_PAGE_INSTITUTE_SUSTAINABILITY = 'institute_sustainability';
const PANORAMA_PAGE_INSTITUTE_HEALTHCARE_REPORT = 'institute_healthcare_report';

const Panorama = ({ onSetPanoramaPage }) => {
  const [isReady, setIsReady] = useState(false);
  const { changePage } = useInterface();
  useEffect(() => {
    window.sendBackEvent = (eventName) => {
      if (eventName === PANORAMA_PAGE_LOBBY_PLENARY) {
        changePage(PAGE_LIVE);
      } else {
        onSetPanoramaPage(eventName);
      }
      // console.log('sendBackEvent:', eventName);
    };
    window.setTimeout(() => {
      setIsReady(true);
      if (typeof (document.getElementById('pano')) !== 'undefined' && document.getElementById('pano') != null) {
        embedpano({
          swf: 'krpano/tour.swf', xml: 'krpano/tour.xml', target: 'pano', html5: 'auto', mobilescale: 1.0, passQueryParameters: true, debugmode: true,
        });
      }
    }, 500);
    return () => {
      window.sendBackEvent = null;
    };
  }, []);

  return (
    <>
      <div id="pano" className="component-panorama" />
    </>
  );
};

Panorama.propTypes = {
  onSetPanoramaPage: PropTypes.func.isRequired,
};

Panorama.defaultProps = {
};

export {
  PANORAMA_PAGE_LOBBY_WELCOME,
  PANORAMA_PAGE_LOBBY_HELPDESK,
  PANORAMA_PAGE_LOBBY_PLENARY,
  PANORAMA_PAGE_LOBBY_FIIINSTUTELOUNGE,
  PANORAMA_PAGE_LIBRARY_VIDEOGALLERY,
  PANORAMA_PAGE_LIBRARY_CONTENTQUOTE,
  PANORAMA_PAGE_LIBRARY_CONTENTCOFFEETAB,
  PANORAMA_PAGE_LIBRARY_CONTENTBOOK,
  PANORAMA_PAGE_INSTITUTE_PRESENTATION,
  PANORAMA_PAGE_INSTITUTE_AI,
  PANORAMA_PAGE_INSTITUTE_ROBOTICS,
  PANORAMA_PAGE_INSTITUTE_HEALTHCARE,
  PANORAMA_PAGE_INSTITUTE_SUSTAINABILITY,
  PANORAMA_PAGE_INSTITUTE_HEALTHCARE_REPORT,
};

export default Panorama;
