import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Redirect, Route, Switch, useHistory,
} from 'react-router';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import axios from 'axios';
import clsx from 'clsx';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import StaffContenu from './StaffContenu';
import SondageStatut, { getLabelForSondageStatut } from '../../Lib/Enums/SondageStatut';
import ButtonAjouter from '../../components/UI/ButtonAjouter';
import EditPoll from './EditPoll';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import Button from '../../components/UI/Button';
import useInterface from '../../Lib/useInterface';

const DragHandle = SortableHandle(() => <div className="stdHandle" />);
const SortableItem = SortableElement(({
  key, sondage, addEditSondage, swapStatut,
}) => (
  <tr key={key} className={clsx(sondage.statut === SondageStatut.ACTIVE && 'stdTable-trGreened')}>
    <td><DragHandle /></td>
    <td>{sondage.question}</td>
    <td>{sondage.sondageReponses && sondage.sondageReponses.totalCount}</td>
    <td>{getLabelForSondageStatut(sondage.statut)}</td>
    <td>
      {addEditSondage && <Button small onClick={addEditSondage && (() => addEditSondage(sondage.id))}>Edit</Button>}
      { sondage.statut === SondageStatut.ACTIVE && <Button className="smallMargin-l" small onClick={() => swapStatut(sondage, SondageStatut.STOPPED)}>Stop</Button> }
      { sondage.statut === SondageStatut.STOPPED && <><Button className="smallMargin-l" small onClick={() => swapStatut(sondage, SondageStatut.ACTIVE)}>Active</Button><Button className="smallMargin-l" small onClick={() => swapStatut(sondage, SondageStatut.ARCHIVED)}>Archive</Button></> }
      { sondage.statut === SondageStatut.ARCHIVED && <Button className="smallMargin-l" small onClick={() => swapStatut(sondage, SondageStatut.STOPPED)}>Un-archive</Button> }
    </td>
  </tr>

));

const SortableList = SortableContainer(({
  sondages, addEditSondage, swapStatut,
}) => (
  <tbody>
    { sondages.map((sondage) => (
      <SortableItem
        key={sondage.id}
        index={sondage.priority}
        sondage={sondage}
        addEditSondage={addEditSondage}
        swapStatut={swapStatut}
      />
    ))}
  </tbody>

));

const PollsListe = ({ statuts, addEditSondage }) => {
  const [lastAction, setLastAction] = useState(DateTime.local());
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [sondages] = useRestClient(`staff/sondages?statuts=${statuts.join(',')}`, null, [lastAction]);

  if (!sondages) {
    return <Chargement />;
  }

  const doSwapSondage = async (from, to) => {
    try {
      await axios.get(`/staff/sondages/swap/${from}/${to}`);
      setLastAction(DateTime.local());
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  const swapStatut = async (sondage, newStatut) => {
    let label = null;
    switch (newStatut) {
      case SondageStatut.ACTIVE: {
        label = 'The poll had been activated';
        break;
      }
      default:
      case SondageStatut.STOPPED: {
        label = 'The poll had been stopped';
        break;
      }
      case SondageStatut.ARCHIVED: {
        label = 'The poll had been archived';
        break;
      }
    }

    try {
      await axios.post(`/staff/sondages/${sondage.id}/changeStatut`, {
        newStatut,
      });
      setLastAction(DateTime.local());
      sendMessage(label);
    } catch (err) {
      sendRestErrorMessage(err);
    }
  };

  return (
    <StaffContenu titre="Polls" rightContent={addEditSondage && <ButtonAjouter onClick={() => addEditSondage()}>Add a poll</ButtonAjouter>}>

      { sondages.length ? (
        <table className="stdTable table-100">
          <thead>
            <tr>
              <th>Question</th>
              <th>State</th>
              <th>Voters</th>
              <th>Action</th>
            </tr>
          </thead>
          <SortableList
            sondages={sondages}
            onSortEnd={({ oldIndex, newIndex }) => {
              doSwapSondage(sondages[oldIndex - 1].id, sondages[newIndex - 1].id);
            }}
            swapStatut={swapStatut}
            addEditSondage={addEditSondage}
            helperClass="stdTable-draggingHelper"
            useDragHandle
          />
        </table>
      ) : (
        <div className="stdNoElements">
          <div>
            <div>There is currently no polls</div>
            <div className="midMargin-y">Do you want to create one ?</div>
            <ButtonAjouter onClick={() => addEditSondage()}>Add a poll</ButtonAjouter>
          </div>
        </div>
      )}
    </StaffContenu>
  );
};

PollsListe.propTypes = {
  addEditSondage: PropTypes.func,
  statuts: PropTypes.array.isRequired,
};
PollsListe.defaultProps = {
  addEditSondage: null,
};

const Polls = () => {
  const history = useHistory();

  const addEditSondage = (idSondage = null) => {
    history.push(idSondage ? `/polls/editer/${idSondage}` : '/polls/ajouter');
  };

  return (
    <div className="component-staffProgram stdStaffSpaceWithMenu">
      <div className="stdStaffSpaceWithMenu-menu stdTab">
        <div className="stdTab-titre"><span>Live Polls</span></div>
        <div className="stdTab-content stdStaffStdMenu">
          <NavLink to="/polls/active">Active</NavLink>
          <NavLink to="/polls/ajouter">Create new poll</NavLink>
          <NavLink to="/polls/archives">Archived</NavLink>

        </div>
      </div>
      <div className="stdStaffSpaceWithMenu-content">
        <Switch>
          <Route exact path="/polls/ajouter" render={() => <EditPoll onCancel={() => history.push('/polls')} onSuccess={() => history.push('/polls')} />} />
          <Route exact path="/polls/editer/:id" render={(props) => <EditPoll {...props} onCancel={() => history.push('/polls')} onSuccess={() => history.push('/polls')} />} />
          <Route exact path="/polls/active" render={() => <PollsListe addEditSondage={addEditSondage} statuts={[SondageStatut.ACTIVE, SondageStatut.STOPPED]} />} />
          <Route exact path="/polls/archives" render={() => <PollsListe statuts={[SondageStatut.ARCHIVED]} />} />
          <Redirect from="*" to="/polls/active" />
        </Switch>

      </div>
    </div>

  );
};

export default Polls;
