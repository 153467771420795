import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import logo from '../../../img/FII/logo.svg';
import twitter from '../../../img/FII/twitter.svg';
import linkedIn from '../../../img/FII/linkedIn.svg';
import topRayures from '../../../img/FII/topRayures.svg';
import youtube from '../../../img/FII/youtube.svg';
import instagram from '../../../img/FII/instagram.svg';
import flickr from '../../../img/FII/flickr.svg';
import facebook from '../../../img/FII/facebook.svg';
import MenuWithCallback from '../../components/UI/Menu/MenuWithCallback';
import DropdowMenu from '../../components/UI/Menu/DropdowMenu';
import useSecurity from '../../Lib/useSecurity';
import StaffMenu from './Menus/StaffMenu';
import GuestMenu from './Menus/GuestMenu';
import SpeakersMenu from './Menus/SpeakersMenu';
import Notification from './Notification';
import useInterface from '../../Lib/useInterface';

const PAGE_LIVE = 'live';
const PAGE_LOBBY = 'lobby';
const PAGE_NETWORKING = 'networking';
const PAGE_CONCLAVE = 'conclave';
const PAGE_INTERVIEW = 'interview';

const Header = () => {
  const iframeRef = useRef();
  const [willLogout, setWillLogout] = useState(false);

  const history = useHistory();
  const { changePage } = useInterface();
  const { iframeToken } = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const currentPage = useSelector((state) => state.interfaceDataReducer.currentPage);

  const logout = async () => {
    await axios.get('logout');
    if (parseInt(process.env.FEATURE_NOTIFICATIONS) === 1) {
      setWillLogout(true);
    } else {
      window.location.href = '/chargement';
    }
  };

  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const comingInterviewNb = useSelector((state) => state.stateDataReducer.comingInterviewNb);

  const {
    isGuest, isMedia, isVIP, isConcierge, isSpeaker, isStaff,
  } = useSecurity();
  return (
    <header className={clsx('stdHeader', isStaff() && 'stdHeader-forStaff')}>
      <div className="stdHeader-background"><img src={topRayures} /></div>
      <div className="stdContainer stdContainer-withPadding">

        <button className="stdHeader-left stdHeader-logo" type="button" onClick={() => history.push('/')}><img src={logo} alt="" /></button>

        <nav className="stdHeader-center">
          <button onClick={() => changePage(PAGE_LIVE)} className={clsx(currentPage === 'live' && 'active', 'stdHeader-center-lobbyButton')} type="button"><div>Live</div></button>
          <button onClick={() => changePage(PAGE_LOBBY)} className={clsx(currentPage === 'lobby' && 'active')} type="button"><div>Lobby</div></button>
          { (!isSpeaker() && !isMedia()) && <button onClick={() => changePage(PAGE_NETWORKING)} className={clsx(currentPage === 'networking' && 'active')} type="button"><div>Networking</div></button> }
          { (!!comingInterviewNb.nbConclaves || isConcierge()) && <button onClick={() => changePage(PAGE_CONCLAVE)} className={clsx(currentPage === 'conclave' && 'active')} type="button"><div>Conclave { !!comingInterviewNb.nbConclaves && <div className="stdHeader-center-nb">{comingInterviewNb.nbConclaves}</div>}</div></button> }
          { !!comingInterviewNb.nbInterviews && <button onClick={() => changePage(PAGE_INTERVIEW)} className={clsx(currentPage === 'interview' && 'active')} type="button"><div>Interview { !!comingInterviewNb.nbInterviews && <div className="stdHeader-center-nb">{comingInterviewNb.nbInterviews}</div>}</div></button> }

        </nav>

        <div className="stdHeader-right">
          <div>
            <DropdowMenu menu={(
              <>
                { (isGuest() || isMedia() || isVIP() || isConcierge() || isMedia()) && <GuestMenu />}
                { isSpeaker() && <SpeakersMenu />}
                { isStaff() && <StaffMenu />}

                <MenuWithCallback onChoose={logout}>Logout</MenuWithCallback>
              </>
            )}
            >
              <span className="hideTablet">Welcome {loggedUtilisateur.firstname} !</span>
            </DropdowMenu>
            <button className="showTablet" type="button" onClick={logout}>Logout</button>

            { parseInt(process.env.FEATURE_NOTIFICATIONS) === 1 && (
              <>
                <Notification />
                { willLogout && (
                  <iframe
                    src={`${process.env.DATARIUM_CHAT_URL}/logout?token=${iframeToken}`}
                    frameBorder="0"
                    ref={iframeRef}
                    style={{ display: 'none' }}
                    enablejsapi="1"
                    onLoad={async () => {
                      await axios.get('logout');
                      window.location.href = '/chargement';
                    }}
                  />
                ) }
              </>
            )}
          </div>
          <div>
            <a href="https://twitter.com/fiiksa" target="_blank" rel="noopener"><img src={twitter} /></a>
            <a href="https://www.linkedin.com/company/fiiksa/" target="_blank" rel="noopener"><img src={linkedIn} /></a>
            <a href="https://www.youtube.com/channel/UCXEQ3MCLUTyUv_Hk_XLyC6g" target="_blank" rel="noopener"><img src={youtube} /></a>
            <a href="https://www.instagram.com/fiiksa/" target="_blank" rel="noopener"><img src={instagram} /></a>
            <a href="https://www.flickr.com/photos/future_investment_initiative" target="_blank" rel="noopener"><img src={flickr} /></a>
            <a href="https://www.facebook.com/FIIKSA" target="_blank" rel="noopener"><img src={facebook} /></a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

export {
  PAGE_CONCLAVE, PAGE_LIVE, PAGE_LOBBY, PAGE_NETWORKING, PAGE_INTERVIEW,
};
