import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import ButtonClose from '../../components/UI/ButtonClose';
import VideoPopup from '../../components/UI/VideoPopup';
import flecheDouble from '../../../img/FII/flecheDouble.png';
import useBackButton from '../../Lib/useBackButton';

const LibraryVideosVideo = ({ url }) => <VideoPopup url={url} />;

const LibraryVideosElement = ({ titre, children }) => {
  const [opened, setOpened] = useState(false);
  return (
    <div className={clsx('component-libraryVideos-el', opened && 'act')}>
      <button type="button" onClick={() => setOpened(!opened)}>
        <div><img src={flecheDouble} alt="" /></div>
        <span>{ titre }</span>
      </button>
      <div>
        {children}
      </div>
    </div>
  );
};

const LibraryVideos = ({ onClose }) => {
  useBackButton(onClose);


  return (
    <div className={clsx('component-library component-libraryVideos')}>
      <ButtonClose backgrounded onClick={onClose} />
      <div className="component-library-scrollable">
        <div>
          <div className="component-libraryVideos-el-container">
            <LibraryVideosElement titre="FII 4th Edition">
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=sG0JkK5qzfI" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=8_TB6iUBYlY" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=zvH-_vbr47Y" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=1cXAyFaqGHg" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=0zZtQ8E2yOI" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=LQ4cDdDqfRQ" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=pC-VR7KR3jk" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=yOBr7CQXIaE" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=GTolWbu5oWE" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=o9apctDYRgw" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=LCSCD0_VD8s" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=V_8Zjtct7es" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=-2IXYimnQHI" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=UDHzRmlFQcs" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=sxH_oJPwpjc" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=CxMjk0O0hNs" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=mS198J2IqKI" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=wrbDpnDBHYU" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=7z-RRan3wfw" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=jmQ6d29jLPo" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=enqJINzn3OI" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=AEYtHCNs354" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=b1CMXqgN3C0" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=VLUQJCxyHsM" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=JOWaJ1fUgjg" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=_6pHFGWQMcI" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=Bo8nZDRHAwk" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=wg49MPy-YXg" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=afxrPEdWC0U" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=8ZJOZ71sCe4" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=_sgOosiwGqI" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=xvsZQ6aGDxo" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=Vm9-aDIMKo4" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=3pZnG0G7kBQ" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=C93ifgXmW7k" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=QpFqU4um4Wk" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=70c-UKtMRnc" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=KmS0Ud1f6y8" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=-XxDaJCs-iQ" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=pqsaOndqYbc" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=BEvqd4At_Z8" />
            </LibraryVideosElement>
            <LibraryVideosElement titre="FII 2019 Panels">
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=z5odkKMzyFw" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=qjUMHszK7Vc" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=TKkyGlCdC0Y" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=i8WiTHxR9cM" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=sMQ4lfGY49k" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=odA2UpbQtIE" />
            </LibraryVideosElement>
            <LibraryVideosElement titre="FII 2019 Speakers Interviews">
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=yiYqy15R7ZU" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=gmpQ8a-tkDc" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=_lAC6_J3QMc" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=GhADwn0gSwo" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=ZOn6EjVMfB8" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=NUJl4M0azEQ" />
            </LibraryVideosElement>
            <LibraryVideosElement titre="Virtual event">
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=eH_Y5amq79Y" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=gqu2Tb9Ci1c" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=gXcHM0DIBIE" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=uVkC3-dnVJ4" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=ogrSpNoAi0E" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=EeSn5PPQy44" />
            </LibraryVideosElement>
            <LibraryVideosElement titre="FII 2018">
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=5MlOIJ05WCM" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=RufCKOVxDjY" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=0EWsOn1hRbQ" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=ihj2yoe2QSw" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=0Fduf3gQaWg" />
            </LibraryVideosElement>
            <LibraryVideosElement titre="FII 2017 - Highlights">
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=S_fcB-oZfnc" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=b4nYK-fhvaY" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=MzbhAqpCd4E" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=rzzBGOypIZ4" />
              <LibraryVideosVideo url="https://www.youtube.com/watch?v=WmUoe6Jr5W4" />
            </LibraryVideosElement>
          </div>
        </div>
      </div>
    </div>
  );
};

LibraryVideos.propTypes = {
  onClose: PropTypes.func.isRequired,
};

LibraryVideos.defaultProps = {};

export default LibraryVideos;
