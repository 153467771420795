import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import book1 from '../../../img/FII/Bookselves/book1.jpg';
import book2 from '../../../img/FII/Bookselves/book2.jpg';
import book3 from '../../../img/FII/Bookselves/book3.jpg';
import book4 from '../../../img/FII/Bookselves/book4.jpg';
import book5 from '../../../img/FII/Bookselves/book5.jpg';
import book6 from '../../../img/FII/Bookselves/book6.jpg';
import book1pdf from '../../../img/FII/Bookselves/Impact_Winning_the_battle_against_disease_together.pdf';
import book2pdf from '../../../img/FII/Bookselves/dont-forget-our-planet_Post-Event-Report.pdf';
import book3pdf from '../../../img/FII/Bookselves/Impact_A_sustainable_future_is within_our_grasp.pdf';
import book4pdf from '../../../img/FII/Bookselves/23april2020PostEventReport.pdf';
import book5pdf from '../../../img/FII/Bookselves/Impact_Health_equity_A_moral_imperative.pdf';
import book6pdf from '../../../img/FII/Bookselves/dont-forget-about-the-planet.pdf';

import ButtonClose from '../../components/UI/ButtonClose';
import useBackButton from '../../Lib/useBackButton';

const LibraryBooks = ({ onClose }) => {
  useBackButton(onClose);
  return (
    <div className={clsx('component-libraryBooks')}>
      <div>
        <ButtonClose backgrounded onClick={onClose} />
        <div>
          <a href={book1pdf} target="_blank" rel="noopener"><img src={book1} /></a>
          <a href={book2pdf} target="_blank" rel="noopener"><img src={book2} /></a>
          <a href={book3pdf} target="_blank" rel="noopener"><img src={book3} /></a>
          <a href={book4pdf} target="_blank" rel="noopener"><img src={book4} /></a>
          <a href={book5pdf} target="_blank" rel="noopener"><img src={book5} /></a>
          <a href={book6pdf} target="_blank" rel="noopener"><img src={book6} /></a>
        </div>
      </div>
    </div>
  );
};

LibraryBooks.propTypes = {
  onClose: PropTypes.func.isRequired,
};

LibraryBooks.defaultProps = {};

export default LibraryBooks;
