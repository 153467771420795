import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from 'axios';
import { DateTime } from 'luxon';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import NotificationMessageType, { getLabelForNotificationMessageType } from '../../Lib/NotificationMessageType';
import useInterface from '../../Lib/useInterface';
import Category from '../../Lib/Category';
import usePubsub from "../../Lib/usePubsub";

const EmergencyDashboard = ({ programmeId }) => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [lastAction, setLastAction] = useState(DateTime.local());

  const [data] = useRestClient(`speaker/${programmeId}/notifications`, null, [lastAction]);
  const dummyEnd = useRef();
  useEffect(() => {
    if (dummyEnd.current) {
      dummyEnd.current.parentNode.scrollTop = dummyEnd.current.offsetTop;
    }
  }, [data && data.notifications]);
  //
  // useEffect(() => {
  //   const EventSource = NativeEventSource || EventSourcePolyfill;
  //   const topic = `${process.env.SITENAME}/programme/${programmeId}/notifications`;
  //   const es = new EventSource(`${process.env.MERCURE_URL}?topic=${topic}`);
  //   es.onmessage = (e) => {
  //     const dataEvent = JSON.parse(e.data);
  //     if (dataEvent.action === 'reFetch') {
  //       setLastAction(DateTime.local());
  //     }
  //   };
  //   return () => {
  //     es.close();
  //   };
  // }, []);

  usePubsub(`programme/${programmeId}/notifications`, () => {
    setLastAction(DateTime.local());
  });

  if (!data) {
    return <Chargement />;
  }

  const { predefinedMessages, notifications } = data;

  const sendNotification = async (message) => {
    try {
      await axios.post(`speaker/${programmeId}/notifications`, {
        message,
      });

      sendMessage('The message has been sent');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  const getFromLabel = (author) => {
    if (author.category === Category.STAFF) {
      return 'Staff';
    }

    return author.firstname;
  };

  return (
    <div className="stdEmergencyDashboard">

      <div>
        <h2>EMERGENCY DASHBOARD</h2>

        <div className="stdEmergencyDashboard-message-container">
          { notifications.map(({
            id, message, author, created,
          }) => (
            <div key={id} className={clsx('stdEmergencyDashboard-message', author.category === Category.STAFF && 'stdEmergencyDashboard-message-fromStaff')}>
              <div>
                <div>{`${getFromLabel(author)}`}</div>
                { created && <div>{created.toFormat('HH:mm')}</div> }
              </div>
              <div>{message}</div>
            </div>
          ))}
          <div style={{ float: 'left', clear: 'both' }} ref={dummyEnd} />
        </div>
      </div>

      { [NotificationMessageType.VIEW, NotificationMessageType.SOUND, NotificationMessageType.OTHER].map((loopType) => (
        <div key={loopType}>
          <h3>{ getLabelForNotificationMessageType(loopType) }</h3>
          <div className="stdEmergencyDashboard-emergency-container">
            { predefinedMessages.filter(({ type }) => type === loopType).map((predefinedMessage) => (
              <button
                type="button"
                className="stdEmergencyDashboard-emergency"
                key={predefinedMessage.id}
                onClick={() => sendNotification(predefinedMessage.content)}
              >{ predefinedMessage.content }
              </button>
            ))}
          </div>
        </div>
      ))}

    </div>
  );
};

EmergencyDashboard.propTypes = {
  programmeId: PropTypes.number.isRequired,
};

export default EmergencyDashboard;
