import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { convertNodeToElement } from 'react-html-parser';
import Button from '../../components/UI/Button';
import whatsapp from '../../../img/whatsapp.svg';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import ButtonClose from '../../components/UI/ButtonClose';
import Popup from '../../components/UI/Popup/Popup';
import Video from '../../components/UI/Video';
import InputWithIcon from '../../components/UI/Form/InputWithIcon';
import useBackButton from '../../Lib/useBackButton';

const InfoDesk = ({ onClose, withBackground, dontOverflowQuestions }) => {
  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const config = useSelector((state) => state.stateDataReducer.config);
  const [search, setSearch] = useState();
  const [isViewingTutorial, setIsViewingTutorial] = useState(false);
  useBackButton(onClose);
  const url = !search ? 'faq-questions' : `faq-questions?search=${search}`;

  const [data] = useRestClient(url, null, [search]);

  const [country] = loggedUtilisateur.visitingCountry ? [loggedUtilisateur.visitingCountry] : useRestClient('getCountry');

  if (!data || !country) {
    return <Chargement />;
  }

  const transform = (node, index) => {
    if (node.type === 'tag') {
      if (node.type === 'tag' && node.name === 'a') {
        return convertNodeToElement(node, index, transform);
      }
      return null;
    }
  };

  const options = {
    decodeEntities: true,
    transform,
  };

  return (
    <div className={clsx('component-infoDesk', withBackground && 'component-infoDesk-withBackground')}>
      <ButtonClose onClick={onClose} backgrounded />
      { isViewingTutorial && (
        <Popup onClosePopup={() => setIsViewingTutorial(false)} variant="noPadding">
          <Video url={config.tutorialVideo} autoPlay />
        </Popup>
      ) }
      <div className="component-infoDesk-top">
        <div className="center">
          <h1>How can we help you?</h1>
        </div>
        <hr className="stdHr stdHr-gradiented" />

        {/* <Button iconAfter="play" onClick={() => setIsViewingTutorial(true)} variant="rounded">Watch Tutorial</Button> */}
        <Button onClick={() => setIsViewingTutorial(true)} variant="rounded gradiented">Watch Tutorial <i className="ptl-play" /></Button>
        {/*<h2>Frequently asked questions</h2>*/}
        {/*<div>Please search by using keywords.</div>*/}
        {/*<InputWithIcon icon="fleche" onChange={setSearch} placeholder="type your question or keyword here..." value={search} />*/}
      </div>

      {/*<div className={clsx('component-infoDesk-content', dontOverflowQuestions && 'component-infoDesk-content-dontOverflowQuestions')}>*/}
      {/*  {data.map(({ id, question, reponse }, index) => (*/}
      {/*    <div className="component-infoDesk-content-question" key={id}>*/}
      {/*      /!* <img src={questionImg} /> *!/*/}
      {/*      <div>{index + 1 ? `0${index + 1}` : index + 1}</div>*/}
      {/*      <div>*/}
      {/*        <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: question }} />*/}
      {/*        <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: reponse }} />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*</div>*/}

      <div className="component-infoDesk-help">
        <button>
          <img src={whatsapp} alt="Whatsapp" />
          <button
            type="button"
            onClick={() => {
              const number = country === 'SA' ? config.whatsappNumberKsa : config.whatsappNumberOtherRegions;
              window.open(`https://api.whatsapp.com/send?phone=${number}&text=&source=&data=&app_absent=`, '_blank');
            }}
          >Connect on WhatsApp
          </button>
        </button>
      </div>
    </div>
  );
};

InfoDesk.propTypes = {
  onClose: PropTypes.func.isRequired,
  dontOverflowQuestions: PropTypes.bool,
  withBackground: PropTypes.bool,
};

InfoDesk.defaultProps = {
  dontOverflowQuestions: false,
  withBackground: false,
};

InfoDesk.defaultProps = {};
export default InfoDesk;
