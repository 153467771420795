import React, { useState } from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import axios from 'axios';
import PublicChat from './PublicChat';
import EmergencyDashboard from './EmergencyDashboard';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import Visio from '../Visio';
import Button from '../../components/UI/Button';
import Live from '../Live/Live';
import useInterface from '../../Lib/useInterface';

const SpeakerSpaceProgrammes = ({ setProgrammeId }) => {
  const { sendRestErrorMessage } = useInterface();

  const [programmes] = useRestClient('speaker/programmes');
  if (!programmes) {
    return <Chargement />;
  }

  const configExternalZoom = true;

  const doJoinExternal = async (programmeId) => {
    try {
      const result = await axios.get(`/speaker/programmes/${programmeId}/getJoinUrl`);
      window.open(result.data.join_url, '_blank');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  return (
    <div className="stdTab stdTab stdTab-leftAlignTitre">
      <div className="stdTab-titre">Your incoming events</div>
      <div className="stdTab-content">
        { programmes.length ? (
          <table className="stdTable table-100">
            <tbody>
              {programmes.map((programme) => (
                <tr key={programme.id}>
                  <td>{programme.name}</td>
                  <td>{programme.realDateDebut && programme.realDateDebut.toFormat('DD - HH:mm')} to {programme.realDateFin && programme.realDateFin.toFormat('HH:mm')}</td>
                  <td className="rightAlign">
                    <Button
                      onClick={() => {
                        if (configExternalZoom) {
                          doJoinExternal(programme.id);
                        } else {
                          setProgrammeId(programme.id);
                        }
                      }}
                      small
                      disabled={!programme.realDateFin || programme.realDateDebut > (DateTime.local()).plus({ minutes: 230 })}
                    >join the green room
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div
            className="center midPadding-y"
            style={{
              height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
          >No event upcoming
          </div>
        ) }
      </div>
    </div>
  );
};

SpeakerSpaceProgrammes.propTypes = {
  setProgrammeId: PropTypes.number.isRequired,
};

const SpeakerSpace = () => {
  const [programmeId, setProgrammeId] = useState(null);

  const location = useLocation();

  const isViewingLive = location.pathname === '/live';

  return (
    <>
      <div className="stdSpeakerSpace-top">
        {isViewingLive ? <><Live /> <PublicChat /></> : (
          <>
            {/* {programmeId ? <div>visio</div> : <SpeakerSpaceProgrammes setProgrammeId={setProgrammeId} />} */}
            {programmeId ? <Visio onBack={() => setProgrammeId(null)} programmeId={programmeId} /> : <SpeakerSpaceProgrammes setProgrammeId={setProgrammeId} />}
            <PublicChat />
          </>
        )}
      </div>

      {programmeId && (
        <div className="stdSpeakerSpace-bottom">
          <EmergencyDashboard programmeId={programmeId} />
        </div>
      )}
    </>
  );
};
export default SpeakerSpace;
