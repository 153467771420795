import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header, { PAGE_CONCLAVE } from './Header/Header';
import MainSpace from './MainSpace/MainSpace';
import useSecurity from '../Lib/useSecurity';
import StaffSpace from './StaffSpace/StaffSpace';
import Cookies from './Cookies';
import SwitchUserPannel from './SwitchUserPannel';
import useInterface from '../Lib/useInterface';

const LoggedApp = () => {
  const currentPage = useSelector((state) => state.interfaceDataReducer.currentPage);
  const { changePage } = useInterface();
  const { isStaff, isConcierge } = useSecurity();

  useEffect(() => {
    if (!currentPage && isConcierge()) {
      changePage(PAGE_CONCLAVE);
    }
  }, [currentPage]);

  return (
    <BrowserRouter>
      <Header />
      { (() => {
        if (isStaff()) {
          return <StaffSpace />;
        }
        return <MainSpace />;
      })()}

      <SwitchUserPannel />
      <Cookies />
    </BrowserRouter>
  );
};

export default LoggedApp;
