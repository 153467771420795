const BREAKING_CHANGE_TIMESTAMP = 1600414607; // http://www.timestamp.fr/

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');

    if (serializedState === null) {
      return {};
    }

    const loaded = JSON.parse(serializedState);

    if (!loaded.cacheBuster || loaded.cacheBuster < BREAKING_CHANGE_TIMESTAMP * 1000) {
      return undefined;
    }


    const { cacheBuster, ...state } = loaded;

    return state;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const stateWithCacheBusting = {
      ...state,
      cacheBuster: Date.now(),
    };
    const serializedState = JSON.stringify(stateWithCacheBusting, (key, value) => {
      if (value && value.$$typeof != null) {
        return null;
      }
      return value;
    });
    localStorage.setItem('state', serializedState);
  } catch (err) {
    console.log('Error in saving store', err);
  }
};

