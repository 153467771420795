import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import useRestClient from '../../Lib/useRestClient';
import EvenementState from '../../Lib/EvenementState';
import topRight from '../../../img/FII/topRight.png';
import botLeft from '../../../img/FII/botLeft.png';
import google from '../../../img/FII/google.png';
import apple from '../../../img/FII/apple.png';
import logo from '../../../img/FII/logo.svg';
import Fieldset from '../../components/UI/Form/Fieldset';
import Button from '../../components/UI/Button';
import Chargement from '../../components/UI/Chargement';
import LoginCompteur from './LoginCompteur';

const Login = ({ match }) => {
  const recaptchaRef = useRef();
  const [error, setError] = useState(null);

  const [config] = useRestClient('/config');
  const shouldDisplayLogin = (config && parseInt(config.isFinished) !== EvenementState.UNSTARTED) || (match && match.params && match.params.code === 'testFII!!!');
  // const shouldDisplayLogin = false || (match && match.params && match.params.code === 'testFII!!!');

  const tryConnect = async (password, captcha, setSubmitting = null) => {
    try {
      await axios.post('login_check', {
        username: password,
        password,
        captcha,
      });
      window.location.href = '/chargement';
    } catch (err) {
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (err.response.data.code === 444) {
        setError('Your ip is not whitelisted');
      } else {
        setError('Please verify your password');
      }

      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    }
  };

  useEffect(() => {
    if (match && match.params.lien) {
      tryConnect(match.params.lien, 'ed59bfc238f82a8e3f75464e557c2bad43f6a431af2e2d634a07d2e7622f0d42');
    }
  }, []);

  const onChangeCapcha = (val) => {
  };

  // shouldDisplayLogin = false;

  return (
    <div className="component-connexion">
      <img src={topRight} className="component-connexion-topRight" />
      <img src={botLeft} className="component-connexion-botLeft" />

      <div className="component-connexion-logos">
        <img src={logo} />

      </div>
      <hr className="stdHr" />

      {/* <h1 className="stdTitle center bigMargin-b">Connexion</h1> */}
      <Formik
        initialValues={{
          password: '',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const captchaVal = await recaptchaRef.current.executeAsync();
            setError(null);
            await tryConnect(values.password, captchaVal);
            setSubmitting(false);
          } catch (err) {
            setSubmitting(false);
            // console.log('captcha err', err);
          }
        }}

        validationSchema={Yup.object().shape({
          password: Yup.string().required('Please provide your password'),
        })}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form>

            {!shouldDisplayLogin ? (
              <>
                <h2>Welcome to the future investment initiative</h2>
                <h3>Please come back on January 27 at 3:30PM (Arabia Standard Time UTC+3), <br />
                  1:30PM (Paris UTC+1), 7:30AM (New-York UTC-5), 8:30PM (Beijing UTC+8), 7:00PM (Mumbai UTC+5:30)
                </h3>

                <LoginCompteur date={DateTime.fromFormat('27/01/2021 13:30', 'dd/LL/yyyy HH:mm', {
                  zone: 'UTC+1',
                })}
                />
                <h4>Before the show</h4>
              </>
            ) : (
              <>
                <div className="component-connexion-centre">
                  <h3>The <em>4th Edition of the Future Investment Initiative (FII)</em> is accessible upon registration only. <br/> Please insert below the FII access code received by email.</h3>

                  <Fieldset type="password" placeholder="Insert access code here..." name="password" onIconClic={() => handleSubmit()} />
                  <a href="https://registration.futureinvestmentinitiative.com/forget-password" target="_blank" className="component-connexion-password">Forgot your access code?</a>
                  <div className="component-connexion-captcha">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey="6Ldcq9EZAAAAAI7TW8UyizDAMUnD1L230q4S5OBz"
                      onChange={onChangeCapcha}
                    />
                  </div>
                  <Button type="submit" variant="big">Enter</Button>
                  {isSubmitting && <Chargement withoutText />}
                  {error && <div className="error smallMargin-t center">{error}</div>}
                </div>
                <div className="component-connexion-bot">
                  <h3>Download the Future Investment Initiative Institute mobile app to stay up <br/> to date with key sessions, speakers and program announcements.</h3>
                  <a target="_blank" rel="noopener" href="https://play.google.com/store/apps/details?id=mobile.appec7xge5Qwi"><img src={google} /></a>
                  <a target="_blank" rel="noopener" href="https://apps.apple.com/us/app/fii/id1549612172"><img src={apple} /></a>
                </div>
              </>
            )}

          </Form>
        )}
      </Formik>
    </div>
  );
};

Login.propTypes = {
  match: PropTypes.object,
};

Login.defaultProps = {
  match: null,
};
export default Login;
